/* ESSE ARQUIVO NÃO DEVE SER CUSTOMIZADO */


import React, { ReactNode, useContext, useEffect, useState } from "react";

interface NavigationLink {
    text: string;
    href: string;
    title: string;
    isExternal: boolean;
    onNavigate?: () => void;
}

interface Profile {
    logo?: string;
    name?: string;
    description?: string;
    categories?: string[];
}

interface Documents {
    cpf?: string;
    cnpj?: string;
}

interface Contacts {
    email?: string;
    phone?: string;
}

interface SocialNetworks {
    twitter?: string;
    instagram?: string;
    facebook?: string;
    whatsapp?: string;
}

interface GlobalContextType {
    profile?: Profile;
    contacts?: Contacts;
    documents?: Documents;
    socialNetworks?: SocialNetworks;
    navigationLinks?: NavigationLink[];
}

const GlobalContext = React.createContext<GlobalContextType | undefined>(undefined);

interface GlobalProviderProps {
    children: ReactNode;
}

const GlobalProvider = ({ children }: GlobalProviderProps) => {

    const [navigationLinks, setNavigationLinks] = useState<NavigationLink[] | undefined>(undefined);

    const value = {
        navigationLinks
    };

    useEffect(()=>{

        setNavigationLinks([
            {
                "text": "Serviços",
                "href": "#services",
                "title": "conheça nossos serviços",
                "isExternal": false
            },
            {
                "text": "Sobre",
                "href": "#about",
                "title": "Conheça nossa empresa",
                "isExternal": false
            },
            {
                "text": "Artigos",
                "href": "#articles",
                "title": "Leia nosso blog",
                "isExternal": false
            },
            {
                "text": "Avalie-nos",
                "href": "https://g.page/r/CVE8xzAxPijvEBM/review",
                "title": "Nos avalie",
                "isExternal": true
            }
        ])

    },[])

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

const useGlobalContext = () => {
    const context = useContext(GlobalContext);
    if (context === undefined) {
        throw new Error("useGlobalContext must be used within a GlobalProvider");
    }
    return context;
};

export { GlobalProvider, useGlobalContext };

    export type {
        Contacts, Documents, GlobalContextType, NavigationLink,
        Profile, SocialNetworks
    };

