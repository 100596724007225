import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { address } from "../config";


const createCmsService = (): AxiosInstance => {
  const axiosConfig: AxiosRequestConfig = {
    baseURL: address.cms,
  };

  return axios.create(axiosConfig);
};

const useCmsService = (): AxiosInstance => {
  return createCmsService();
};

export { useCmsService };

