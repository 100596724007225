import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
const Footer = React.lazy(()=>import("./blades/footer"))
const Navbar = React.lazy(()=>import("./blades/navbar"))
const Tooltip = React.lazy(()=>import("./components/tooltip"))
const PostDetailsPage = React.lazy(()=>import("./pages/articleDetailsPage"))
const HomePage = React.lazy(()=>import("./pages/homePage"))

function App() {
  return <>
    <header>
      <Tooltip/>
      <Navbar/>
    </header>
    <main>
      <Routes>
        <Route path="/" element={<Suspense><HomePage/></Suspense>} />
        <Route path="/home" element={<Suspense><HomePage/></Suspense>} />
        <Route path="/postagens/post/:slug" element={<Suspense><PostDetailsPage/></Suspense>} />
      </Routes>
    </main>
    <Footer/>
  </>
}

export default App;
 