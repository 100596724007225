import ReactDOM from 'react-dom/client';

import App from './App';
import './template.css';

import { PrimeReactProvider } from 'primereact/api';

import { GlobalProvider } from './provider/global';
import { StoreProvider } from './provider/store';

import "primereact/resources/themes/lara-light-cyan/theme.css";
import { BrowserRouter } from 'react-router-dom';

import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <SkeletonTheme baseColor="#fff9f9" highlightColor="#d0d0d0">
    <PrimeReactProvider>
      <GlobalProvider>
        <StoreProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StoreProvider>
      </GlobalProvider>
    </PrimeReactProvider>
  </SkeletonTheme>
);

