import { GiArcheryTarget } from "react-icons/gi";
import { IoDiamondOutline, IoEyeOutline } from "react-icons/io5";

import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

import { address } from "../config";
import { useCmsService } from "../services/cmsService";

enum ContactsEnum {
    email = "email",
    facebook = "facebook",
    instagram = "instagram",
    phone = "phone",
    twitter = "twitter",
    whatsapp = "whatsapp"
}

interface StoreContextType {
    services?: ServiceItem[];
    missions?: MissionItem[];
    comments?: CommentItem[];
    articles?:ArticleItem[];
    contacts?:ContactItem[];
}

interface ServiceItem {
    id: number;
    count: number;
    description: string;
    link: string;
    name: string;
    slug: string;
    taxonomy: string;
    meta: any[];
    acf: {
        icon:string
    };
    _links: Links;
}

interface MissionItem {
    icon: JSX.Element;
    title: string;
    description: string;
}

interface CommentItem {
    id: number;
    count: number;
    description: string;
    link: string;
    name: string;
    slug: string;
    taxonomy: string;
    meta: any[];
    acf: any[];
    _links: Links;
}

interface ContactItem {
    id: number;
    count: number;
    description: string;
    link: string;
    name: string;
    slug: string;
    taxonomy: string;
    meta: any[];
    _links: Links;
}

interface ArticleItem {
    id: number;
    count: number;
    description: string;
    link: string;
    name: string;
    slug: string;
    taxonomy: string;
    meta: any[];
    acf: {
        category:string,
        cover:string,
        keywords:string,
        content:any
    };
    _links: Links;
}

interface Links {
    self: Link[];
    collection: Link[];
    about: Link[];
    "wp:post_type": Link[];
    curies: Curie[];
}

interface Link {
    href: string;
}

interface Curie {
    name: string;
    href: string;
    templated: boolean;
}

const StoreContext = React.createContext<StoreContextType | undefined>(undefined);

interface StoreProviderProps {
    children: ReactNode;
}

const StoreProvider = ({ children }: StoreProviderProps) => {

    const CmsService = useCmsService();

    const [ contacts, setContacts ] = useLocalStorage < ContactItem[] | undefined >  ( 'cache-contacts' , undefined );
    const [ services, setServices ] = useLocalStorage < ServiceItem[] | undefined >  ( 'cache-services' , undefined );
    const [ missions, setMissions ] = useState        < MissionItem[] |  undefined > (                              );
    const [ comments, setComments ] = useLocalStorage < CommentItem[] | undefined >  ( 'cache-comments' , undefined );
    const [ articles, setArticles ] = useLocalStorage < ArticleItem[] | undefined >  ( 'cache-articles' , undefined );


    const value = {
        services,
        missions,
        comments,
        articles,
        contacts
    };

    const getCmsServices = async () => {
        CmsService.get(address.services).then(response => {
            const services = response.data as ServiceItem[];
            setServices(services);
        });
    }

    const getCmsComments = async () => {
        CmsService.get(address.comment).then(response => {
            const comments = response.data as CommentItem[];
            setComments(comments);
        });
    }

    const getCmsArticles = async () => {
        CmsService.get(address.articles).then(response => {
            const articles = response.data as ArticleItem[];
            setArticles(articles);
        });
    }

    const getCmsContacts = async () => {
        CmsService.get(address.contacts).then(response => {
            const contacts = response.data as ContactItem[];
            setContacts(contacts);
        });
    }

    useEffect(() => {

        const missions = [
            {
                "icon": <GiArcheryTarget />,
                "title": "Missão",
                "description": "Prover soluções integradas de manutenção e instalação, superando as expectativas dos nossos clientes ao garantir segurança, qualidade e um atendimento personalizado."
            },
            {
                "icon": <IoEyeOutline/>,
                "title": "Visão",
                "description": "Ser reconhecida como a empresa líder em soluções residencial, predial e industrial, superando as expectativas dos nossos clientes através de um trabalho de excelência, promovendo a fidelização e cultivando relacionamentos duradouros e de confiança."
            },
            {
                "icon": <IoDiamondOutline/>,
                "title": "Valores",
                "description": "Nos dedicamos a oferecer um atendimento de alta qualidade, entendendo e resolvendo as necessidades específicas de nossos clientes com eficiência e profissionalismo."
            }
        ]

        setMissions(missions);
        
    }, []);

    useEffect(() => {
        
        getCmsComments();
        getCmsArticles();
        getCmsContacts();
        getCmsServices();

    }, []);

    return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
}

const useStoreContext = () => {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error("useStoreContext must be used within a StoreProvider");
    }
    return context;
}

export { ContactsEnum, StoreProvider, useStoreContext };
export type { ArticleItem, CommentItem, ContactItem, MissionItem, ServiceItem };

